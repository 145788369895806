import router from "../router/index.js"
import axios from 'axios'

const login = {
    namespaced: true,
    state: () => ({
        user: 'test',
        password: 123,
        mostrar: true,

         usuario : {
            login: "",
            password: "",
            token: "",
          }
    }),

    mutations: {

        setUsuario(state, payload) {
            state.usuario = payload;
            //console.log("state.usuario")
            //console.log(state.usuario)
            //console.log(state.usuario.token)

            if (state.usuario.token != ""){
                //console.log("state.usuario.token-alonso")
                state.mostrar = false
                //localStorage.setItem("token",  state.usuario.token);
                //localStorage.setItem("usuario",  state.usuario.usuario);     
            }else{
                state.mostrar = true
                usuario.login = ""
            }
            
          },
        
    },
    actions: {
        async login({ commit },usuario) {
            await axios
                .get(
                    "https://juanabapl.juanabonita.com.co:446/api/api/Cifin/" +
                    //"https://localhost:44397/api/Cifin/" +
                    "?usuario=" +
                    usuario.login +
                    "&password=" +
                    usuario.password +
                    '',
                  
                )
                .then((response) => {
                    //console.log(response);
                    let data = response.data
                    //console.log(data);
                    commit("setUsuario", data);
                })
                .catch((e) => {
                  console.log("error");
                  console.log(e);
                });
          },

         cambiarMotrar({ commit,state }) {
             state.mostrar = true
             console.log("Entro mostrar")
        },


    },
    getters: {
    }
}
export {login}