<template>
  <div :class="fondoclass" class="h-4/5">
    <div :class="classModal" class="h-3/5">
      <div
        class="shadow-sm flex justify-center w-8 h-8 border-2 border-red-600 rounded text-center"
      >
        <button @click="cerrarModal">
          <p class="font-bold tect-red-500">x</p>
        </button>
      </div>
      <hr />

      <div class="overflow-auto h-full text-justify">
        <div class="grid justify-items-center">
          <img :src="img" alt="" />
        </div>

        <div class="grid justify-items-center">
          <div class="grid grid-cols-2 gap-4">
            <button
              class="relative mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              v-if="disabledBottonReenvio == true"
              @click.prevent="reeEnvioSMS()"
            >
              Reenvio SMS
            </button>

            <button
              class="relative mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              v-if="disabledBottonReenvio == true"
              @click.prevent="solicitarCuestionario()"
            >
              Solicitar Cuestionario
            </button>
          </div>

          <p class="text-2xl my-2">
            Favor ingresar el código informado por la asesora
          </p>
          <input
            id="Idcodigo"
            name="Idcodigo"
            type="text"
            required
            class="appearance-none rounded-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm my-2 text-2xl text-center"
            placeholder="Código"
            v-model="codigoSMSdigitado"
            :disabled="inputCodigoDisabled"
          />

          <button
            class="relative mt-2 flex justify-left py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-pink-800 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            @click.prevent="enviarSMS()"
          >
            Enviar Código
          </button>
        </div>
        <!--<div class="grid justify-items-center">
          <img :src="img" alt="" />
        </div>-->
      </div>
      <modalLoading v-show="modalLoad"></modalLoading>
      <modalProps
        v-show="mostrarModal"
        :titulo="tituloM"
        :msg1="mensajeM"
        :tipos="tipoM"
      ></modalProps>
    </div>
  </div>
</template>
<script>
import img from "../assets/logos/LogoJuana.png";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, watchEffect, onMounted } from "vue";
import router from "../router/index.js";
import modalLoading from "../components/modalLoading.vue";
import modalProps from "../components/modalPropos.vue";

export default {
  name: "noticeModal",
  components: {
    modalLoading,
    modalProps,
  },

  setup() {
    const store = useStore();
    const asesoraAux = computed(() => store.state.asesora);
    const disabledBottonReenvio = ref(true);
    const codigoSMSdigitado = ref("");
    const tituloM = ref("");
    const mensajeM = ref("");
    const tipoM = ref("");
    const contadorEnvioSms = ref(0);
    const inputCodigoDisabled = ref(false);
    const lstPreguntas = computed(() => store.state.preguntas);
    const codigoOTPreenvo = computed(() => store.state.codigoOTP);
    const contadorReenvioCodigo = ref(0);
    const contadorReenvioCuestionario = ref(0);
    //const nombreAsesoraAux = computed(() => store.state.nombreAsesora);

    //console.log("Vinculacion Manual-Nombre asesora")
    //console.log(nombreAsesoraAux)

    const mostrarModal = computed(() => store.state.mostrarModal);
    watchEffect(() => {
      mostrarModal.value = computed(() => store.state.mostrarModal);
    });

    const modalLoad = computed(() => store.state.mostrarModalLoading);
    watchEffect(() => {
      modalLoad.value = computed(() => Store.state.mostrarModalLoading);
    });

    const cerrarmodalLoading = () => {
      store.commit("closeModalLoading");
    };

    const classModal = ref(
      "border border-4 border-gray-400 shadow rounded shadow bg-white grid grid-cols-1 justify-items-center   relative mx-2 z-50  px-3 py-2 flex items-center justify-center rounded absolute right-0 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 mt-8 lg:mt-10"
    );
    const fondoclass = ref(
      "z-10 z-40 overflow-auto scrolling-touch p-3 left-0 top-0 bottom-0 right-0  fixed bg-opacity-50 bg-gray-900 "
    );

    const cerrarModal = () => {
      store.commit("ModalCodigo");
      //router.push("/cargaInicial");
      contadorReenvioCodigo.value = 0;
    };

    const enviarSMS = async () => {
      if (codigoSMSdigitado.value == "") {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "No puede enviar un código en blanco",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });

        return;
      }

      contadorEnvioSms.value++;
      if (contadorEnvioSms.value <= 2) {
        cerrarmodalLoading();
        await store.dispatch("envioSMS", codigoSMSdigitado.value);

        let ctrlRpta = asesoraAux.value.controlMensaje;
        let mensaje = "";

       

        if (ctrlRpta == "NOTOKEN") {
          Swal.fire({
            title: "Mensaje del sistema!",
            text: "TOKEN VENCIDO, Favor Iniciar sesion nuevamente",
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          }).then(async (result) => {
            if (result.isConfirmed) {
              /*Swal.fire(
                  'Registro!',
                  'Registro Ingresado',
                  'success'
                )*/
              store.commit("LimpiarVariables");
              router.push("/");
              await store.dispatch("login/cambiarMotrar");
            }
          });
        } else if (ctrlRpta == "301") {
          mensaje = "Código OTP no recibido";
        } else if (ctrlRpta == "302") {
          mensaje = "Código OTP incorrecto";
        } else if (ctrlRpta == "303") {
          mensaje = "Reintento debido a error de OTP";
        } else if (ctrlRpta == "304") {
          mensaje = "Validación fallida debido a error del sistema";
        } else if (asesoraAux.value.controlMensaje == "300") {
          cerrarmodalLoading();
          cerrarModal();
          store.commit("setModalDirecciones");
          router.push("/about");
        } else if (
          asesoraAux.value.controlMensaje == "302" ||
          asesoraAux.value.controlMensaje == "303"
        ) {
          cerrarmodalLoading();

          Swal.fire({
            title: "Mensaje del sistema!",
            text: mensaje + " volver a digitar el código",
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });
          codigoSMSdigitado.value = "";

          //store.commit("LimpiarVariables");
          //router.push("/");
        } else if (asesoraAux.value.controlMensaje == "301") {
          cerrarmodalLoading();
          Swal.fire({
            title: "Mensaje del sistema!",
            text:
              mensaje +
              " Favor solicitar un reenvio de código o solicitar cuestionario",
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });
          codigoSMSdigitado.value = "";
        } else if (asesoraAux.value.controlMensaje == "304") {
          cerrarmodalLoading();
          Swal.fire({
            title: "Mensaje del sistema!",
            text: mensaje,
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });

          codigoSMSdigitado.value = "";
          store.commit("LimpiarVariables");
          router.push("/");
        } else {
          cerrarmodalLoading();
          Swal.fire({
            title: "Mensaje del sistema!",
            text: mensaje,
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });

          codigoSMSdigitado.value = "";
          store.commit("LimpiarVariables");
          router.push("/");
        }
      } else {
        inputCodigoDisabled.value = true;
        Swal.fire({
          title: "Mensaje del sistema!-6",
          text: "No tienes más oportunidades,Favor solicitar un reenvio de código o solicitar cuestionario",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
        codigoSMSdigitado.value = "";
        //disabledBottonReenvio.value = true
      }
    };

    const reeEnvioSMS = async () => {
      contadorReenvioCodigo.value++;

      if (contadorReenvioCodigo.value <= 1) {
        // disabledBottonReenvio.value = false
        //errarmodalLoading();
        await store.dispatch("reEnvioSMS");

        if (codigoOTPreenvo.value.trim() == "NOTOKEN") {
          Swal.fire({
            title: "Mensaje del sistema!",
            text: "TOKEN VENCIDO, Favor Iniciar sesion nuevamente",
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          }).then(async (result) => {
            if (result.isConfirmed) {
              /*Swal.fire(
                  'Registro!',
                  'Registro Ingresado',
                  'success'
                )*/
              store.commit("LimpiarVariables");
              router.push("/");
              await store.dispatch("login/cambiarMotrar");
            }
          });
        } else if (
          codigoOTPreenvo.value != "" ||
          codigoOTPreenvo.value != "undefined"
        ) {
          //console.log('reenvio otp 2')
          //console.log(codigoOTPreenvo.value)

          Swal.fire({
            title: "Mensaje del sistema!",
            text: "Código reenviado correctamente.",
            icon: "success",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });
          inputCodigoDisabled.value = false;
          contadorEnvioSms.value = 0;
        }
      } else {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "El reenvio de código solo se puede realizar una vez, favor reenviar cuestionario o volver al inicio",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      }
    };

    const showButton = async () => {
      //disabledBottonReenvio.value = true
      inputCodigoDisabled.value = false;
    };

    const solicitarCuestionario = async () => {
      contadorReenvioCuestionario.value++;
      if (contadorReenvioCuestionario.value <= 1) {
        await store.dispatch("reEnvioCustionario");

        if (lstPreguntas.value.controlMensaje == "NOTOKEN") {
          Swal.fire({
            title: "Mensaje del sistema!",
            text: "TOKEN VENCIDO, Favor Iniciar sesion nuevamente",
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          }).then(async (result) => {
            if (result.isConfirmed) {
              /*Swal.fire(
                  'Registro!',
                  'Registro Ingresado',
                  'success'
                )*/
              store.commit("LimpiarVariables");
              router.push("/");
              await store.dispatch("login/cambiarMotrar");
            }
          });
        } else if (lstPreguntas.value.controlMensaje == "EXAMDATA") {
          cerrarmodalLoading();
          cerrarModal();
          //console.log("alonso")
          //console.log(lstPreguntas.value)

          router.push("/question");
        } else {
          Swal.fire({
            title: "Mensaje del sistema!",
            text: "El sistema no envio cuestionario para realizar",
            icon: "error",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#F3566E",
          });
        }
      } else {
        Swal.fire({
          title: "Mensaje del sistema!",
          text: "El cuestionario solo se puede solicitar una vez, favor reenviar código o volver al inicio",
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F3566E",
        });
      }
    };

    /*const solicitarFormVinculacionSinOtp = async () => {
      //cerrarmodalLoading();
      cerrarModal();
      await store.commit("vinculacionManual");
      router.push("/about");
    };*/

    onMounted(() => showButton());

    return {
      classModal,
      fondoclass,
      cerrarModal,
      img,
      enviarSMS,
      asesoraAux,
      cerrarmodalLoading,
      modalLoad,
      reeEnvioSMS,
      disabledBottonReenvio,
      showButton,
      codigoSMSdigitado,
      tituloM,
      mensajeM,
      tipoM,
      mostrarModal,
      contadorEnvioSms,
      inputCodigoDisabled,
      solicitarCuestionario,
      lstPreguntas,
      contadorReenvioCodigo,
      contadorReenvioCuestionario,
      //solicitarFormVinculacionSinOtp,
      //nombreAsesoraAux,
    };
  },
};
</script>

<style>
</style>